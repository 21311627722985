/* css for form input */

/* .e-input-form.e-input-group.e-control-wrapper {
  border-bottom: rgb(237, 125, 49) !important;
} */

.form-input .e-input {
  border-bottom: rgb(237, 125, 49) !important;
}

/* css for date picker */

/* .e-input-group.e-control-wrapper.e-custom {
  border-color: rgb(237, 125, 49) !important;
} */
.e-custom-date {
  border-bottom-color: rgb(237, 125, 49) !important;
  padding-left: 12px;
}
.e-custom {
  padding-left: 7px;
  font-size: 20px;
}

.e-custom .e-header {
  margin: 10px;
}

.e-custom .e-content {
  padding: 10px;
}

/* css for gender selection */

.e-custom-gender {
  padding: 0px 12px;
  font-size: 24px;
}

.e-custom-gender.e-input-group.e-control-wrapper {
  border-color: rgb(237, 125, 49) !important;
}

.e-custom-gender.e-ddl.e-input-group .e-input-group-icon,
.e-ddl.e-input-group.e-control-wrapper .e-input-group-icon:hover {
  visibility: hidden;
}

/* new css */
.confirm-centre1 {
  display: flex;
  justify-content: start;
  align-items: center;
}

.confirm-centre2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-centre3 {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
  font-weight: 500;
  font-size: 14px;
}
.detail-center1 {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
  font-weight: 500;
  font-size: 14px;
}
.message-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-btn {
  display: flex;
  justify-content: right;
  align-items: center;
}

/* 05-10-2023 */
#details-page {
  position: relative;
}

.change-btn {
  position: absolute;
  bottom: 0;
  right: 20px;
  padding: 10px;
}
