.period-selector {
  width: 500px !important;
  min-width: 300px !important;
}

.periodSelector-input {
  border: none !important;
  font-size: 15px;
  width: 70%;
  font-weight: 300;
  min-width: 70%;
  padding: 10px;
}
.periodSelector-icons {
  cursor: pointer;
}
.periodSelector-dropdownIcons {
  margin-left: -3%;
}
/* #payslip-periodcontextmenu {
  width: 18vw;
} */
/* .e-menu-parent .e-ul {
  width: 170px;
} */
