.drop-down {
  width: 150px !important;
}

#contextmenu {
  width: 30px !important;
  height: 190px !important;
  text-align: center !important;
  justify-content: center !important;
}

.attendance-table tbody td tfoot th thead tr {
  padding: 6px !important;
  margin-top: 15px !important;
}

.table-data>tr {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  /* margin-top: 20px !important; */
}

.summary {
  height: 45px !important;
  width: 98.7% !important;

  @-moz-document url-prefix() {
      {
      width: calc(100% - 1.2em) !important;
    }
  }

  border: 1px solid black !important;
  margin-top: 2px !important;
}

@supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee)) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
  .summary {
    height: 45px !important;
    width: calc(100% - 1.3em) !important;




    @-moz-document url-prefix() {
        {
        width: calc(100% - 1.2em) !important;
      }
    }



    border: 1px solid black !important;
    margin-top: 2px !important;

  }
}

.attendance-table tbody tr td {
  padding: 6px !important;
}

.attendance-table thead tr td {
  padding: 6px !important;
}

#attendance-selector {
  cursor: pointer !important;
}

/* table header css  */
.main-hader {
  margin-top: 25px;
  height: calc(100vh - 322px);
  overflow-y: scroll !important;
}

.attendance-table {
  border: 0;
  width: 98.6%;
  border: 0;
  height: 250px;
}

.attendance-table>thead {
  border: 1px solid black;
}

.td-1 {
  /* column-span: 2 !important; */
  width: 22%;
  border: 1px solid black;
}

.td-2 {
  /* column-span: 2 !important; */
  width: 13%;
  border-top: 1px solid black;
  border-bottom: 1px solid darkgrey;
  border-right: 1px solid darkgrey;
}

.td-3 {
  /* column-span: 2 !important; */
  width: 13%;
  border-top: 1px solid black;
  border-bottom: 1px solid darkgrey;
}

.td-4 {
  /* column-span: 4 !important; */
  width: 26%;
  border: 1px solid black;
  border-bottom: 1px solid darkgrey;
}

.td-5 {
  /* column-span: 2 !important; */
  width: 47%;
  border-bottom: 1px solid darkgrey;
}

.text-center {
  border-bottom: 1px solid black;
}

.td-6 {
  border-right: 1px solid darkgray;
  width: 6%;
}

.td-7 {
  border-right: 1px solid black;
  width: 6%;
}

.td-8 {
  border-right: 1px solid darkgray;
  width: 7%;
}

.td-9 {
  border-right: 1px solid black;
  width: 7%;
}

.td-10 {
  border-right: 1px solid darkgray;
  width: 13.2%;
  text-align: right;
}

.td-11 {
  border-right: 1px solid black;
  width: 15%;
  text-align: right;
}

/* blank tr css after header on table */

.blank-space {
  height: 3px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

/* left css for attendance-table */

/* table boday css */

/* .body-data {
  height: calc(100vh - 320px);
  overflow-y: scroll !important;
}

body {
  overflow: overlay;
}

.body-data::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

.body-data::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 2px;
}

.body-td1 {
  border-right: 1px solid darkgray;
  width: 5%;
}

.body-td2 {
  border-right: 1px solid black;
  width: 17%;
  text-align: left;
}

.body-td3 {
  border-right: 1px solid black;
  width: 24% !important;
}

.body-td4 {
  border-right: 1px solid darkgray;
  cursor: pointer;
  user-select: none;
}

.body-td5 {
  border-right: 1px solid black;
  cursor: pointer;
  user-select: none;
}

.body-td6 {
  border-right: 1px solid darkgray;
  width: 6.9%;
}

.body-td7 {
  border-right: 1px solid darkgray;
  width: 7%;
}

.body-td8 {
  border-right: 1px solid darkgray;
  width: 7.1%;
}

.body-td9 {
  border-right: 1px solid black;
  width: 6.9%;
}

.body-td10 {
  border-right: 1px solid darkgray;
  width: 13.2%;
} */

/* summary CSS */

.summary-table {
  height: 100%;
  width: 100%;
}

.summary-td1 {
  width: 5%;
}

.summary-td2 {
  width: 13%;
}

.summary-td3 {
  width: 24%;
}

.summary-td4 {
  width: 6%;
}

.summary-td5 {
  width: 6%;
}

.summary-td6 {
  width: 6%;
}

.summary-td7 {
  width: 6%;
}

.summary-td8 {
  width: 6%;
}

.summary-td9 {
  width: 6%;
}

.summary-td10 {
  width: 17%;
  text-align: right !important;
  padding-right: 5px !important;
}

.totalOvertimeandGross {
  border: 1px solid black !important;
  margin-top: 3px;
  height: 35px;
  width: 100%;
  /* margin-left: 50px; */
}

.PeriodSelector {
  margin-left: 15px;
  float: left !important;
}

.settingicons {
  margin-right: 15px;
  float: right !important;
  font-size: 20px;
  cursor: pointer;
}

.icon-spacing {
  padding: 10px !important;
  cursor: pointer;
}

.content-c-align {
  text-align: center !important;
}



/*CSS made to dislay ruler -- Sandeep*/

div#ruler {
  position: relative;
  top: 5px;
  left: 5px;
  display: flex;
  height: 100px;
  padding: 0 25px;
  border-radius: 12.5px;

  &:after {
    position: relative;
    bottom: 5px;
    left: 25px;
    margin-top: 20px;
  }

  .marks {
    display: flex;
    justify-content: center;
    width: .01cm;
    height: 10px;
    margin-right: .09cm;
    background-color: hsl(0deg, 0%, 5%);

    &.medium {
      height: 15px;
    }

    &.long {
      padding-top: 25px;
      height: 20px;
      font-size: 12px;
      color: black;

      &:last-of-type {
        margin-right: 0;
      }

      &:after {
        margin-top: 20px;
      }
    }
  }
}

.table-container {
  margin-top: 15px !important;
  height: calc(100vh - 268px);
}

.itable {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  user-select: none;
}



.itable .thead {
  flex: 0 0 auto;
  width: calc(100% - 1.2em);
}

@supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee)) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
  .itable .thead {
    flex: 0 0 auto;
    width: calc(100% - 1.4em);
  }
}


.itable .tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
}

.itable .tbody .tr {
  width: 100%;
}

.range {
  background-color: rgb(145, 198, 243) !important;
}


@-moz-document url-prefix() {
  .itable .tbody .tr {
    width: calc(100% - 1.2em);
  }
}



.itable .thead,
.itable .tbody .tr {
  display: table;
  table-layout: fixed;
}

/* decorations */
.table-container {

  padding: 0.1em;
}

.itable {
  border-spacing: 0em;
}

.itable tr {
  padding: 0.1em;
  /* border: 1px solid black; */
}

.itable td {
  padding: 6px !important;
  border: 1px solid rgb(85, 85, 85);
  padding: 0.1em;
}

.itable th {
  padding: 0.1em;
  border: 1px solid black;
  text-align: center !important;
  font-weight: normal !important;
  padding: 6px !important;
}

.itable .td1 {
  width: 18%;
}

.itable .td2 {
  border-bottom: 1px solid darkgrey;
  border-right: 1px solid darkgrey;
  width: 12%;
}

.itable .td3 {
  border-bottom: 1px solid darkgrey;
  width: 12%;
}

.itable .td4 {
  border-bottom: 1px solid darkgrey;
  width: 24%;
}

.itable .td5 {
  border-bottom: 1px solid darkgrey;
  width: 34%;
}

.itable .td6 {
  border-right: 1px solid darkgray;
  width: 6%;
}

.itable .td7 {
  border-right: 1px solid black;
  width: 6%;
}

.itable .td8 {
  border-right: 1px solid darkgray;
  width: 6%;
}

.itable .td9 {
  border-right: 1px solid black;
  width: 6%;
}

.itable .td10 {
  border-right: 1px solid darkgray;
  text-align: right;
  width: 17%;
}

.itable .td11 {
  border-right: 1px solid black;
  text-align: right;
  width: 17%;
}

.itable .bodytd1 {
  border-right: 1px solid darkgray;
  width: 5%;
  text-align: center !important;
}

.itable .bodytd2 {
  border-right: 1px solid black;
  text-align: left;
  width: 13%;
}

.itable .bodytd3 {
  border-right: 1px solid black;
  width: 24%;
  text-align: center !important;
}

.itable .bodytd4 {
  border-right: 1px solid darkgray;
  cursor: pointer;
  user-select: none;
  width: 6%;
  text-align: center !important;
}

.itable .bodytd5 {
  border-right: 1px solid black;
  cursor: pointer;
  user-select: none;
  width: 6%;
  text-align: center !important;
}

.itable .bodytd6 {
  border-right: 1px solid darkgray;
  width: 6%;
  text-align: center !important;
}

.itable .bodytd7 {
  border-right: 1px solid darkgray;
  width: 6%;
  text-align: center !important;
}

.itable .bodytd8 {
  border-right: 1px solid darkgray;
  width: 6%;
  text-align: center !important;
}

.itable .bodytd9 {
  border-right: 1px solid black;
  width: 6%;
  text-align: center !important;
}

.itable .bodytd10 {
  border-right: 1px solid darkgray;
  width: 17%;
}


/* .ruler {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  position: relative;
  margin: 4em 2em;
  height: 2em;
  padding: 0;
  border-top: 1px solid;
  color: steelblue;
  width: max-content;
  font: .95em Arial;
}

.ruler::before {
  display: block;
  width: max-content;
  position: absolute;
  bottom: 100%;
  left: 2px;
  transform: translate(-50%, -1em)
}

.ruler li::after {
  display: block;
  width: max-content;
  overflow: visible;
  color: steelblue;
  text-align: right;
  transform: translate(50%, -1.5em);
}

.ruler li {
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  position: relative;
  width: 1cm;
  transform: translate(0, -.75em);
  box-sizing: border-box;
  border-left: 2px steelblue solid;
  padding-top: 25px;
  color: black;
  font-size: 12px;
  text-indent: -16px;
} */