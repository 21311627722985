/* Identity page styling */
.identity-input {
  border: none !important;
  border-bottom: 1px solid rgb(237, 125, 49) !important;
}
.identity-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
  font-weight: 500;
  font-size: 14px;
}
.nextbtn {
  display: flex;
  justify-content: right;
  align-items: center;
  /* position: relative;
  bottom: 30px;
  right: 20px; */
}
.aruti-tos {
  text-decoration: underline;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  cursor: pointer;
}
.signup-text {
  margin-left: 10%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
/* .StepperContainer-0-2-1 {
  padding: 1px !important;
} */
#confirm-div {
  height: '250px';
}
#identity-div {
  height: '268px';
}
#details-div {
  height: '255px';
}
.message-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-btn {
  display: flex;
  justify-content: right;
  align-items: center;
}
#message-div {
  height: '274px';
}

.confirm-centre3 {
  font-size: 14px;
  display: flex !important;
  justify-content: right !important;
  align-items: center !important;
}
.detail-center1 {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
  font-weight: 500;
  font-size: 14px;
}

#signup-btn {
  color: white;
  background-color: rgb(237, 125, 49);
  font-size: 16px;
}
#confirm1-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
  font-weight: 500;
  font-size: 14px;
  /* padding: 50px 10px !important; */
}
